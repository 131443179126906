<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />

        <div slot="content">
            <div class="container">
                <div class="about-div">


                     <div class="text-center pb-4" >
                            <img src="../../../assets/images/logo2.png" height="80">
                        </div>
                             <div class="div-container pt-4">
                    <div class="info-block">
                        <div class="num-block">
                            <div class="num-case fw-600">
                                <h3>rQ</h3>
                            </div>
                        </div>
                        <div class="block-detail">
                            <h4>{{tran.req_tech}}</h4>
                            <p>{{tran.req_tech_detail}}</p>
                        </div>
                        <div class="logo-block">
                            <img src="../../../assets/images/req.png" height="30">
                    </div>
                        </div>

                    </div>
                             <div class="div-container pt-4">
                    <div class="info-block">
                        <div class="num-block">
                            <div class="num-case fw-600">
                                <h3>VB</h3>
                            </div>
                        </div>
                        <div class="block-detail">
                            <h4>{{tran.version}}</h4>
                            <p>{{tran.version_detail}}</p>
                        </div>
                        <div class="logo-block">
                            <img src="../../../assets/images/req.png" height="30">
                    </div>
                        </div>

                    </div>
                             <div class="div-container pt-4">
                    <div class="info-block">
                        <div class="num-block">
                            <div class="num-case fw-600">
                                <h3>Or</h3>
                            </div>
                        </div>
                        <div class="block-detail">
                            <h4>{{tran.origin}}</h4>
                            <p>{{tran.origin_detail}}</p>
                        </div>
                        <div class="logo-block">
                            <img src="../../../assets/images/req.png" height="30">
                    </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Wrapper>
</div>
</template>

<script>
import Navbar from '../../partials/Navbar'
import Sidebar from '../../partials/Sidebar'
import Footer from '../../partials/Footer'
import Wrapper from '../../partials/Wrapper'
import trans from "../../../../translations";
import searchProduct from "../SearchProduct";
import http from "../../../http/index";
import {
    commonJs
} from '../mixins/commonJs.js';

import {
    mapState
} from "vuex";

export default {
    mixins: [commonJs],
    data() {

        return {

        }
    },

    watch: {

    },
    methods: {

    },
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
        searchProduct
    },

    computed: {
        ...mapState({

            accountOverview: state => state.market.accountOverview,

        }),

    },
    mounted() {
        let heading = {

            main: this.tran.abt_req,
        }
        this.$store.commit('market/savePageHeading', heading)
        this.tran = trans;

    },
}
</script>

<style scoped>

</style>
