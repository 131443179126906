<template>
<div>
    <About />
</div>
</template>

<script>
import About from '../../components/dashboard/helpSupport/About.vue'
export default {
    components: {
        About
    }
}
</script>

<style>
    